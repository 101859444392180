import React, { useState, useRef, useEffect ,startTransition} from 'react';
import { useLocation ,Link} from 'react-router-dom';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Header from "../../component/header/Header";
import StepperMenu from '../../component/Stepper/StepperMenu';
import Section from '../../component/Stepper/Section';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Footer from '../../component/footer/Footer';
import useWindowDimensions from '../../customHook/useWindowDimensions/useWindowDimensions ';
import { fetchDefaultLocation } from '../../apiaction/actions';
import { useDispatch, useSelector } from 'react-redux';
import { originalKey } from './constMenu';
import Skeleton from '@mui/material/Skeleton';
import './Menu.css';
import { Margin } from '@mui/icons-material';
const Menu = ({ getLocation, headermenu, alldata, themeData, businessHours, carryoutHours, footerData, allLocation, allLocationNavigtion, ourMenu , loading, res_id, mainTheme, webLocationLength,webmenuitemData,loading1}) => {
  const dispatch = useDispatch();
  const secretKey = '0Xi82iggZJRlO1Tg6Jo3J4IrHw7zUb';
  localStorage.setItem('items', secretKey);
  const resid = res_id;
  localStorage.setItem('resid', resid);
  const subsecretKey = '0i2gZROT6oJIH7U';
  localStorage.setItem('subitems', subsecretKey);
  const storedEncryptedKey = localStorage.getItem('items');
  const substoredEncryptedKey = localStorage.getItem('subitems');
  const { pathname } = useLocation();
    useEffect(() => {
      if(ourMenu.design_layout == 1){
        window.scrollTo(0, 0);
      }else if(ourMenu.design_layout == 2){
        if (loading == false && storedEncryptedKey == originalKey) {
          var about1 = window.location.href;
          var about2 = window.location.href.split("#").pop();
          if(about1 != about2){
            if(about2 != '' || about2.length > 0){
              var about = document.getElementById(about2);
              if (about !== null) {  
                setTimeout(() => {         
                  about.scrollIntoView({ behavior: 'smooth',block: "end", inline: "nearest" }); 
                }, 10)
              }
            }
          }
        }
      }
    }, [pathname]);
    
  const [activeStep, setActiveStep] = useState(0);
  const sectionRefs = useRef([]);
  const { height, width } = useWindowDimensions();
  const windowWidth = width;
  const handleScroll = () => {
    if (sectionRefs.current) {
      sectionRefs.current.forEach((ref, index) => {
        const { top } = ref.getBoundingClientRect();
        if (top >= 0 && top < window.innerHeight / 2) {
          setActiveStep(index);
        }
      });
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setActiveStep(0);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const location = useLocation();
  let webmenuitemDataSlotAll = webmenuitemData;
  // if(location.pathname === `/${allLocationNavigtion}/menu/slot=1`){
  //   webmenuitemDataSlotAll = webmenuitemData;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu/slot=2`){
  //   webmenuitemDataSlotAll = webmenuitemData;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu/slot=3`){
  //   webmenuitemDataSlotAll = webmenuitemData;
  // }
  // else if(location.pathname === `/${allLocationNavigtion}/menu`){
  //   webmenuitemDataSlotAll = webmenuitemData;
  // }
  // else{
  //  console.log('Slot is not found');
  // }
  const backgroundStyle = ourMenu.is_page_bg === 1
  ? { backgroundColor: ourMenu.page_bg }
  : {
      backgroundImage: ourMenu.page_bg ? `url(${ourMenu.page_bg})` : 'none'
    };
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    if (loading == false && storedEncryptedKey == originalKey) {
        return (
          <>         
              <Box 
                sx={{  
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top left',
                backgroundSize: 'cover',
                position: 'relative',
                overflow: 'hidden',
                '&::after': {
                  content: '""',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  ...backgroundStyle,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100vw 100vh',
                  zIndex: -1,
                  opacity:'1',
                }
                }}>
                  {/* {loading1 ? (<Skeleton className='skeleton' variant="rectangular" width="100%" height={106} sx={{mb:2}}/>):( */}
                    <Header getLocation={getLocation} headermenu={headermenu} alldata={alldata} themeData={themeData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme} webLocationLength={webLocationLength}/>
                  {/* )} */}
              {ourMenu.design_layout == 1 && (
                <>
                  <Box sx={{ mt: '120px', maxWidth:'1368px',width:'95%',margin:'auto',backgroundColor:ourMenu.page_bg}}>
                    <div className="app">
                      <StepperMenu activeStep={activeStep}  webmenuitemDataSlotAll={webmenuitemDataSlotAll} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} substoredEncryptedKey={substoredEncryptedKey} webmenuitemData={webmenuitemData} loading1={loading1}/>
                      <div className="content">
                      {loading1 ? (
                        <React.Fragment>
                          <Skeleton className='skeleton' variant="rectangular" height={50} sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',my:2}}/>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded"
                                className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton menuItemsCard'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ):(
                        <React.Fragment>
                            {webmenuitemDataSlotAll?.Category_list?.map((Category_list, categoryIndex) => (
                              <Section className="mainSection"
                                key={Category_list.id}
                                id={Category_list.id}
                                ref={el => (sectionRefs.current[categoryIndex] = el)}
                                substoredEncryptedKey={substoredEncryptedKey}
                                layout={ourMenu.design_layout}
                              >
                                <Box sx={{marginBottom:'20px'}}>
                                <Card sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',backgroundColor:ourMenu.category_box_color}}>
                                  <Typography variant='h4' 
                                    sx={{
                                      color:ourMenu.category_title_color,
                                      fontFamily:ourMenu.category_title_family,
                                      textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                      paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    }}>{Category_list.name}</Typography>
                                  <Typography variant='h6' 
                                    sx={{
                                      color:ourMenu.category_title_color,
                                      fontFamily:ourMenu.category_title_family,
                                      fontWeight:400,
                                      textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                      paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    }}>{Category_list.description}</Typography>
                                </Card>
                                </Box>
                                <Grid container>
                                  {Category_list?.menu_item?.map((menu_item,index)=>(
                                    <Grid item xs={12} sm={12} md={6} lg={4} key={index} 
                                      sx={{
                                        paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                        paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                        paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                        paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                        "&:nth-child(1)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(2)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(3)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                          paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                        },
                                        "&:nth-child(4)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(5)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(6)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(7)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(8)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(9)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                          paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                        },
                                        "&:nth-child(10)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                          paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                        },
                                        "&:nth-child(11)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(12)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(13)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        },
                                        "&:nth-child(14)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(15)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                        },
                                        "&:nth-child(16)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(17)":{
                                          paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                        },
                                        "&:nth-child(18)":{
                                          paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                        }
                                      }}
                                      >
                                      <Card 
                                        className='menuItemsCard'
                                          sx={{
                                          backgroundColor:ourMenu.item_box_color,
                                          margin:{
                                            xs:'auto',
                                            sm:'auto',
                                            md:'block',
                                            lg:'block'
                                          }
                                        }}
                                        >
                                        <CardMedia
                                          sx={{borderRadius:'8px'}}
                                          component="img"
                                          height="220.922px"
                                          image={windowWidth < 900 ? menu_item.image != '' ? `${menu_item.image}`: `${Category_list.image}` : menu_item.image_medium != '' ? `${menu_item.image_medium}` : `${Category_list.image}`}
                                          alt={`${menu_item.name}`}
                                        />
                                        <CardContent>
                                          <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',my:1}}>
                                            <Typography sx={{color:ourMenu.item_title_color,fontWeight:700,fontSize:'20px',fontFamily:`${ourMenu.category_title_family}`}}>{menu_item.name}</Typography>
                                            <Typography sx={{color:ourMenu.item_title_color,fontWeight:400,fontSize:'19px'}}>{menu_item.price != '' ? '$':''}{menu_item.price}</Typography>
                                          </Box>
                                          <Typography variant='p' sx={{fontSize:'14px',fontWeight:'300',color:ourMenu.item_des_color,fontFamily:`${ourMenu.item_des_family}`}}>
                                            {menu_item.description}
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              </Section>
                            ))}
                        </React.Fragment>
                      )}
                      </div>
                    </div>
                  </Box>
                </>
              )}
              {ourMenu.design_layout == 2 && (
                <>
                  <Box sx={{ mt: '120px', maxWidth:'1368px',width:'95%',margin:'auto',backgroundColor:ourMenu.page_bg}}>
                    <div className="app">
                      <StepperMenu activeStep={activeStep}  webmenuitemDataSlotAll={webmenuitemDataSlotAll} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} substoredEncryptedKey={substoredEncryptedKey} webmenuitemData={webmenuitemData} loading1={loading1}/>
                      <div className="content">
                      {loading1 ? (
                        <React.Fragment>
                          <Skeleton className='skeleton' variant="rectangular" height={50} sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',my:2}}/>
                          <Grid container className='layout2'>
                            <Grid item xs={12} sm={12} md={6} lg={3}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                         
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton'
                                sx={{
                                  height:'300.422px',
                                  width:'auto',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                         
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton'
                                sx={{
                                  height:'300.422px',
                                  width:'auto',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                         
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton'
                                sx={{
                                  height:'300.422px',
                                  width:'auto',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                         
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton'
                                sx={{
                                  height:'300.422px',
                                  width:'auto',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>                                                
                          </Grid>                      
                        </React.Fragment>
                      ):(
                        <React.Fragment>                       
                          <div className='section_layput2'>
                            <Box sx={{marginBottom:'20px'}}>
                              <Card sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',backgroundColor:ourMenu.category_box_color}}>
                                <Typography sx={{color:ourMenu.category_title_color,fontWeight:700,fontSize:'30px',fontFamily:`${ourMenu.category_title_family}`}}>Select A Category</Typography>
                              </Card>
                            </Box>                         
                            <Grid container>
                              {webmenuitemDataSlotAll?.Category_list?.map((Category_list, categoryIndex) => (                                      
                                <Grid item xs={12} sm={12} md={6} lg={3}                                 
                                id={Category_list.id}
                                ref={el => (sectionRefs.current[categoryIndex] = el)}  
                                sx={{
                                    paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                    paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                                    
                                  }}
                                  >                                   
                                  <Link className="link2" to={`/${allLocationNavigtion}/menuitem/${Category_list.id}`}>
                                       
                                      <Card 
                                        className='menuItemsCard_layout2'
                                          sx={{
                                          backgroundColor:ourMenu.item_box_color,
                                          margin:{
                                            xs:'auto',
                                            sm:'auto',
                                            md:'block',
                                            lg:'block'
                                          }
                                        }}
                                        >                                    
                                        <CardContent>
                                          <Box sx={{alignItems:'center',justifyContent:'space-between',my:1}}>
                                            <Typography sx={{color:ourMenu.category_title_color,backgroundColor:ourMenu.category_box_color,fontWeight:700,fontSize:'20px',fontFamily:`${ourMenu.category_title_family}`}}>{Category_list.name}</Typography>
                                          </Box>
                                        </CardContent>
                                        <CardMedia
                                          sx={{borderRadius:'0px 0px 8px 8px'}}
                                          component="img"
                                          height="230.922px"
                                          image={windowWidth < 900 ? Category_list.image != '' ? `${Category_list.image}`: `${Category_list.image}` : Category_list.image_medium != '' ? `${Category_list.image_medium}` : `${Category_list.image}`}
                                          alt={`${Category_list.name}`}
                                        />
                                      </Card>
                                    
                                  </Link>                                  
                                </Grid>                                                           
                              ))}
                            </Grid>
                          </div>                       
                        </React.Fragment>
                      )}
                      </div>
                    </div>
                  </Box>
                </>
              )}
              
              {ourMenu.design_layout == 3 && (
                <>
                  <Box sx={{ mt: '120px', maxWidth:'1368px',width:'95%',margin:'auto',backgroundColor:ourMenu.page_bg}}>
                    <div className="app">
                      <StepperMenu activeStep={activeStep}  webmenuitemDataSlotAll={webmenuitemDataSlotAll} allLocationNavigtion={allLocationNavigtion} ourMenu={ourMenu} substoredEncryptedKey={substoredEncryptedKey} webmenuitemData={webmenuitemData} loading1={loading1}/>
                      <div className="content">
                      {loading1 ? (
                        <React.Fragment>
                          <Skeleton className='skeleton' variant="rectangular" height={50} sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',my:2}}/>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded"
                                className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='skeleton menuItemsCard'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}  
                              sx={{
                                paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                "&:nth-child(1)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(2)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(3)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(4)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(5)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(6)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(7)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(8)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(9)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(10)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(11)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(12)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(13)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                },
                                "&:nth-child(14)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(15)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                },
                                "&:nth-child(16)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'0px'},
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(17)":{
                                  paddingLeft:{xs:'0px', sm:'0px', md:'0px', lg:'16px'},
                                },
                                "&:nth-child(18)":{
                                  paddingRight:{xs:'0px', sm:'0px', md:'0px', lg:'0px'},
                                }
                              }}
                              >
                              <Skeleton variant="rounded" className='menuItemsCard skeleton'
                                sx={{
                                  height:'403.422px',
                                  width:'403.422px',
                                  margin:'auto',
                                  '@media (min-width:1200px) and (max-width:1300px)': {
                                    width: '100%',
                                  },
                                  '@media (max-width:430px)': {
                                    width: '100%',
                                  },
                                }}/>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ):(
                        <React.Fragment>
                            {webmenuitemDataSlotAll?.Category_list?.map((Category_list, categoryIndex) => (
                              <Section className="mainSection"
                                key={Category_list.id}
                                id={Category_list.id}
                                ref={el => (sectionRefs.current[categoryIndex] = el)}
                                substoredEncryptedKey={substoredEncryptedKey}
                                layout={ourMenu.design_layout}
                              >
                                <Box sx={{marginBottom:'20px'}}>
                                <Card sx={{width:{xs:'100%',sm:'403.422px',md:'50%',lg:'50%'},margin:'auto',backgroundColor:ourMenu.category_box_color}}>
                                  <Typography variant='h4' 
                                    sx={{
                                      color:ourMenu.category_title_color,
                                      fontFamily:ourMenu.category_title_family,
                                      textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                      paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    }}>{Category_list.name}</Typography>
                                  <Typography variant='h6' 
                                    sx={{
                                      color:ourMenu.category_title_color,
                                      fontFamily:ourMenu.category_title_family,
                                      fontWeight:400,
                                      textAlign:{xs:'center',sm:'center',md:'center',lg:'center'},
                                      paddingLeft:{xs:'0px', sm:'0px', md:'8px', lg:'16px'},
                                    }}>{Category_list.description}</Typography>
                                </Card>
                                </Box>
                                <Grid container>
                                  {Category_list.set_layout == 1 && (
                                    <>  
                                      {Category_list?.menu_item?.map((menu_item,index)=>(
                                        <Grid item xs={12} sm={6} md={6} lg={6} key={index} 
                                          sx={{
                                            paddingRight:{xs:'0px', sm:'8px', md:'8px', lg:'16px'},
                                            paddingLeft:{xs:'0px', sm:'8px', md:'8px', lg:'16px'},
                                            paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                            paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                                        
                                          }}
                                          >
                                          <Card 
                                            className='menuItemsCard3'
                                              sx={{
                                              backgroundColor:ourMenu.item_box_color,
                                              margin:{
                                                xs:'auto',
                                                sm:'auto',
                                                md:'block',
                                                lg:'block'
                                              },
                                              borderRadius:'10px',
                                            }}
                                            >
                                            <CardMedia
                                              sx={{borderRadius:'10px 0px 0px 10px',width:'150px !important'}}
                                              component="img"
                                              height="155px"                                          
                                              image={windowWidth < 900 ? menu_item.image != '' ? `${menu_item.image}`: `${Category_list.image}` : menu_item.image_medium != '' ? `${menu_item.image_medium}` : `${Category_list.image}`}
                                              alt={`${menu_item.name}`}
                                            />
                                            <CardContent className='itemContent'>                                          
                                                <Typography sx={{color:ourMenu.item_title_color,fontWeight:700,fontSize:{xs:'15px', sm:'15px', md:'16px', lg:'16px'},fontFamily:`${ourMenu.category_title_family}`}}>{menu_item.name}</Typography>
                                                <Typography className="menuItemsCard3_des" variant='p' sx={{fontSize:{xs:'14px', sm:'14px', md:'15px', lg:'15px'},fontWeight:'300',color:ourMenu.item_des_color,fontFamily:`${ourMenu.item_des_family}`}}>
                                                  {menu_item.description}
                                                </Typography>
                                                <Typography sx={{color:ourMenu.item_title_color,fontWeight:400,fontSize:{xs:'13px', sm:'13px', md:'14px', lg:'14px'},display:'flex',alignItems:'center'}}>
                                                  {menu_item.price != '' ? '$':''}{menu_item.price}
                                                  {menu_item.is_gluten_free == 1 && ( <img src={menu_item.is_gluten_free_img} className="icon_img "/> )}
                                                  {menu_item.is_vegan == 1 && ( <img src={menu_item.is_vegan_img} className="icon_img" /> )}
                                                  {menu_item.is_vegetarian == 1 && ( <img src={menu_item.is_vegetarian_img} className="icon_img" /> )}
                                                  {menu_item.is_how_spicy == 1 || menu_item.is_how_spicy == 2 || menu_item.is_how_spicy == 3 ? ( 
                                                    <img src={menu_item.is_how_spicy_img} className="icon_img" /> 
                                                  ) : null}
                                                </Typography>
                                            </CardContent>
                                          </Card>
                                        </Grid>
                                      ))}
                                    </>
                                  )}
                                  {Category_list.set_layout == 2 && (
                                    <>
                                    {Category_list?.menu_item?.map((menu_item,index)=>(
                                      <Grid item xs={12} sm={6} md={6} lg={4} key={index} 
                                        sx={{
                                          paddingRight:{xs:'0px', sm:'8px', md:'8px', lg:'16px'},
                                          paddingLeft:{xs:'0px', sm:'8px', md:'8px', lg:'16px'},
                                          paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},
                                          paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'16px'},                                        
                                        }}
                                        >
                                        <Card 
                                          className='menuItemsCard2_2'
                                            sx={{
                                            backgroundColor:ourMenu.item_box_color,
                                            margin:{
                                              xs:'auto',
                                              sm:'auto',
                                              md:'block',
                                              lg:'block'
                                            },borderRadius:'10px',
                                          }}
                                          >
                                          <CardMedia
                                            sx={{borderRadius:'10px 10px 0px 0px'}}
                                            component="img"
                                            height="170px"
                                            image={windowWidth < 900 ? menu_item.image != '' ? `${menu_item.image}`: `${Category_list.image}` : menu_item.image_medium != '' ? `${menu_item.image_medium}` : `${Category_list.image}`}
                                            alt={`${menu_item.name}`}
                                          />
                                          <CardContent>
                                              <Typography sx={{color:ourMenu.item_title_color,fontWeight:700,fontSize:{xs:'14px', sm:'14px', md:'15px', lg:'15px'},fontFamily:`${ourMenu.category_title_family}`}}>{menu_item.name}</Typography>
                                              <Typography sx={{color:ourMenu.item_title_color,fontWeight:400,fontSize:{xs:'13px', sm:'13px', md:'14px', lg:'14px'}}}>{menu_item.price != '' ? '$':''}{menu_item.price}</Typography>
                                            
                                              {menu_item.is_gluten_free == 1 && ( <img src={menu_item.is_gluten_free_img} className="icon_img"/> )}
                                              {menu_item.is_vegan == 1 && ( <img src={menu_item.is_vegan_img} className="icon_img" /> )}
                                              {menu_item.is_vegetarian == 1 && ( <img src={menu_item.is_vegetarian_img} className="icon_img" /> )}
                                              {menu_item.is_how_spicy == 1 || menu_item.is_how_spicy == 2 || menu_item.is_how_spicy == 3 ? ( 
                                                <img src={menu_item.is_how_spicy_img} className="icon_img" /> 
                                              ) : null}
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    ))}
                                    </>
                                  )} 
                                  {Category_list.set_layout == 3 && (
                                    <>
                                    {Category_list?.menu_item?.map((menu_item,index)=>(
                                      <Grid item xs={6} sm={4} md={4} lg={3} key={index} 
                                        sx={{
                                          paddingRight:{xs:'4px', sm:'8px', md:'8px', lg:'8px'},
                                          paddingLeft:{xs:'4px', sm:'8px', md:'8px', lg:'8px'},
                                          paddingTop:{xs:'8px', sm:'8px', md:'8px', lg:'8px'},
                                          paddingBottom:{xs:'8px', sm:'8px', md:'8px', lg:'8px'},                                        
                                        }}
                                        >
                                        <Card 
                                          className='menuItemsCard2_2'
                                            sx={{
                                            backgroundColor:ourMenu.item_box_color,
                                            margin:{
                                              xs:'auto',
                                              sm:'auto',
                                              md:'block',
                                              lg:'block'
                                            },borderRadius:'10px',
                                          }}
                                          >
                                          <CardMedia
                                            sx={{borderRadius:'10px 10px 0px 0px'}}
                                            component="img"
                                            height="170px"
                                            image={windowWidth < 900 ? menu_item.image != '' ? `${menu_item.image}`: `${Category_list.image}` : menu_item.image_medium != '' ? `${menu_item.image_medium}` : `${Category_list.image}`}
                                            alt={`${menu_item.name}`}
                                          />
                                          <CardContent sx={{padding:{xs:'8px', sm:'8px', md:'8px', lg:'8px'}}}>
                                              <Typography sx={{color:ourMenu.item_title_color,fontWeight:700,fontSize:{xs:'14px', sm:'14px', md:'15px', lg:'15px'},fontFamily:`${ourMenu.category_title_family}`}}>{menu_item.name}</Typography>
                                              <Typography sx={{color:ourMenu.item_title_color,fontWeight:400,fontSize:{xs:'13px', sm:'13px', md:'14px', lg:'14px'}}}>{menu_item.price != '' ? '$':''}{menu_item.price}</Typography>
                                            
                                              {menu_item.is_gluten_free == 1 && ( <img src={menu_item.is_gluten_free_img} className="icon_img "/> )}
                                              {menu_item.is_vegan == 1 && ( <img src={menu_item.is_vegan_img} className="icon_img" /> )}
                                              {menu_item.is_vegetarian == 1 && ( <img src={menu_item.is_vegetarian_img} className="icon_img" /> )}
                                              {menu_item.is_how_spicy == 1 || menu_item.is_how_spicy == 2 || menu_item.is_how_spicy == 3 ? ( 
                                                <img src={menu_item.is_how_spicy_img} className="icon_img" /> 
                                              ) : null}
                                          </CardContent>
                                        </Card>
                                      </Grid>
                                    ))}
                                    </>
                                  )}                              
                                </Grid>
                              </Section>
                            ))}
                        </React.Fragment>
                      )}
                      </div>
                    </div>
                  </Box>
                </>
              )}
                {/* {loading1 ? (<Skeleton variant="rectangular" width="100%" height={50} className='skeleton'/>):( */}
                  <Footer getLocation={getLocation} businessHours={businessHours} carryoutHours={carryoutHours} themeData={themeData} footerData={footerData} allLocation={allLocation} allLocationNavigtion={allLocationNavigtion} loading={loading} substoredEncryptedKey={substoredEncryptedKey} mainTheme={mainTheme}/>
                {/* )} */}
              </Box>
          </>         
        );
    }else{
      return null;
    }
}

export default Menu;
