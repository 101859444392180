import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  mobileOpen: false,
  appBarColor: '',
  appBarLogo: 'none',
  appBarTopLogo: 'flex',
  appBarPosition: 'absolute',
  appBarPositionTop: '',
  appBarWidthTheme3: '',
  appBarPositionTheme5: '',
  appLogoTheme2: '',
  appIconTheme2: '',
  menuPagePositionTop: '',
  isPromotionVisible: true,
  isActiveMarquee: '',
  isActiveMarqueeclick: 'active',
  openOrder: false,
  expanded: 'panel1',
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setMobileOpen: (state, action) => {
      state.mobileOpen = action.payload;
    },
    setAppBarColor: (state, action) => {
      state.appBarColor = action.payload;
    },
    setAppBarLogo: (state, action) => {
      state.appBarLogo = action.payload;
    },
    setAppBarTopLogo: (state, action) => {
      state.appBarTopLogo = action.payload;
    },
    setAppBarPosition: (state, action) => {
      state.appBarPosition = action.payload;
    },
    setAppBarPositionTop: (state, action) => {
      state.appBarPositionTop = action.payload;
    },
    setAppBarWidthTheme3: (state, action) => {
      state.appBarWidthTheme3 = action.payload;
    },
    setAppBarPositionTheme5: (state, action) => {
      state.appBarPositionTheme5 = action.payload;
    },
    setAppLogoTheme2: (state, action) => {
      state.appLogoTheme2 = action.payload;
    },
    setAppIconTheme2: (state, action) => {
      state.appIconTheme2 = action.payload;
    },
    setMenuPagePositionTop: (state, action) => {
      state.menuPagePositionTop = action.payload;
    },
    setIsPromotionVisible: (state, action) => {
      state.isPromotionVisible = action.payload;
    },
    setIsActiveMarquee: (state, action) => {
      state.isActiveMarquee = action.payload;
    },
    setIsActiveMarqueeclick: (state, action) => {
      state.isActiveMarqueeclick = action.payload;
    },
    setOpenOrder: (state, action) => {
      state.openOrder = action.payload;
    },
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
  },
});

export const {
  setMobileOpen,
  setAppBarColor,
  setAppBarLogo,
  setAppBarTopLogo,
  setAppBarPosition,
  setAppBarPositionTop,
  setAppBarWidthTheme3,
  setAppBarPositionTheme5,
  setAppLogoTheme2,
  setAppIconTheme2,
  setMenuPagePositionTop,
  setIsPromotionVisible,
  setIsActiveMarquee,
  setIsActiveMarqueeclick,
  setOpenOrder,
  setExpanded,
} = headerSlice.actions;

export default headerSlice.reducer;
